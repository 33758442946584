export const Ski = () => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Записаться на занятия к инструктору</h2>
          <h3 className='title'>
            Горные лыжи
          </h3>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/check3.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Стафеев Иван</h3>
              <p>Профиль работы: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 10 лет).</p>
              <p>Категория: 3. Прошел обучение у УЦ ФГССР России в 2021г.</p>
              <p>Стаж преподавания: с 2019 г.</p>
              <p>Стаж индивидуального катания: с 2014 года. </p>
              <p>Вид занятий: индивидуальные тренировки.</p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div>
          {/* <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/check10.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Батурин Юрий</h3>
              <p>Профиль работ: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 10 лет).</p>
              <p>Стаж преподавания: с 2021 г</p>
              <p>Стаж индивидуального катания: с 2013 года.</p>
              <p>Вид занятий: индивидуальные тренировки.</p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div> */}
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/check.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Беспалов Владимир</h3>
              <p>Профиль работы: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 5 лет).</p>
              <p>Категория: кандидат в мастера спорта СССР по горным лыжам.</p>
              <p>Стаж преподавания: более 20-ти лет.</p>
              <p>В будни обучает катанию участников Благотворительного проекта по развитию адаптивных видов спорта «Я знаю - ВСЁ возможно!», который реализуется совместно с МЭБОО «Зелёный полюс». </p>
              <p>По выходным дням - проводит обучения для всех желающих.</p>
              <p>Соревнования: Многократный победитель Кубка Карелии по горным лыжам</p>
              <p>Вид занятий: индивидуальные и групповые тренировки</p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/tren_181122.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Яблоков Андрей</h3>
              <p>Главный инструктор Школы горных лыж YES.</p>
              <p>Профиль работ: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 4 лет).</p>
              <p>Категория: C. Прошел обучение в Национальной лиге инструкторов в соответствии с международными стандартами обучения International Ski Instructors Association (ISIA) - Методика обучения детей технике горнолыжного спорта</p>
              <p>Мончегорская СШОР по горнолыжному спорту. «Федеральный научный центр физической культуры и спорта»(ФГБУ ФНЦ ВНИИФК) по программе : «Теория и методика горнолыжного спорта».</p>
              <p>Судья 3 категории по горнолыжному спорту (Постановление №33 от 11.01.24)</p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/check17.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Горюнов Максим</h3>
              <p>Профиль работы: обучение катанию на горных лыжах. </p>
              <p>Взрослые и дети (с 7 лет)</p>
              <p>Пройдено обучение в Национальной Лиге инструкторов 2024г.</p>
              <p>Стаж преподавания: с 2024 г</p>
              <p>Стаж индивидуального катания: 4 года.</p>
              <p>Вид занятий: индивидуальные тренировки, для учеников без опыта катания. </p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/chek22.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Батенёва Светлана </h3>
              <p>Профиль работы: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 7 лет).</p>
              <p>Пройдено обучение в Национальной Лиге инструкторов 2024г.</p>
              <p>Стаж преподавания: с 2024 г</p>
              <p>Стаж индивидуального катания: с 2009 года.</p>
              <p>Вид занятий: индивидуальные тренировки</p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div>
          {/* <div className='col-md-4'>
            <div className='icon'>
              <img
                src={'img/instructors/check30.jpg'}
                className='img-responsive'
              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Смирнов Никита</h3>
              <p>Профиль работы: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 5 лет).</p>
              <p>Стаж индивидуального катания: с 2017 года.</p>
              <p>Вид занятий: индивидуальные тренировки, для учеников без опыта катания.</p>
              <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20807" className="btn btn-custom btn-lg page-scroll">Записаться</a>
            </div>
          </div> */}
        </div>

      </div>
    </div>
  )
}
